import styles from './styles.scss';
import { inBetween } from './inBetween';
import { isImg } from './isImg';
import { isSlot } from './isSlot';
const template = document.createElement('template');
template.innerHTML = `
  <style>
    ${styles}
  </style>

  <div class="before" id="before">
    <slot name="before"></slot>
  </div>
  <div class="after" id="after">
    <div class="handle-wrapper">
      <slot name="handle">
        <div class="handle"></div>
      </slot>
    </div>
    <div class="after-overlay">
      <div id="afterImageContainer">
        <slot name="after"></slot>
      </div>
    </div>
  </div>
`;
const KeySlideOffset = {
    ArrowLeft: -1,
    ArrowRight: 1,
};
const getTouchPagePoint = (e) => ({
    x: e.touches[0].pageX,
    y: e.touches[0].pageY,
});
export class HTMLImgComparisonSliderElement extends HTMLElement {
    constructor() {
        super();
        this.exposure = 50;
        this.isMouseDown = false;
        this.isFocused = false;
        this.hydrate = (element) => {
            if (!isImg(element)) {
                return;
            }
            if (element.classList.contains('hydrated')) {
                return;
            }
            element.addEventListener('dragstart', (e) => {
                e.preventDefault();
            });
            element.addEventListener('load', this.resetWidth);
            element.classList.add('hydrated');
        };
        this.onWindowMouseMove = (e) => {
            /**
             * This dynamic window.onmousemove event handler
             * registers on mousedown and removes on mouse up.
             * The whole mumbo-jumbo is needed to capture
             * mouse events outside of component. This provides
             * better user experience.
             */
            if (this.isMouseDown) {
                this.slideToPageX(e.pageX);
            }
        };
        this.bodyUserSelectStyle = '';
        this.onMouseDown = (e) => {
            window.addEventListener('mousemove', this.onWindowMouseMove);
            window.addEventListener('mouseup', this.onWindowMouseUp);
            this.isMouseDown = true;
            this.slideToPageX(e.pageX, true);
            this.focus();
            this.bodyUserSelectStyle = window.document.body.style.userSelect;
            window.document.body.style.userSelect = 'none';
        };
        this.onWindowMouseUp = () => {
            this.isMouseDown = false;
            window.document.body.style.userSelect = this.bodyUserSelectStyle;
            window.removeEventListener('mousemove', this.onWindowMouseMove);
            window.removeEventListener('mouseup', this.onWindowMouseUp);
        };
        this.isTouchComparing = false;
        this.hasTouchMoved = false;
        this.onTouchStart = (e) => {
            this.touchStartPoint = getTouchPagePoint(e);
            if (this.isFocused) {
                this.slideToPageX(e.touches[0].pageX, true);
            }
        };
        this.onTouchMove = (e) => {
            if (this.isTouchComparing) {
                this.slideToPageX(e.touches[0].pageX);
                e.preventDefault();
                return false;
            }
            if (!this.hasTouchMoved) {
                const currentPoint = getTouchPagePoint(e);
                if (Math.abs(currentPoint.y - this.touchStartPoint.y) <
                    Math.abs(currentPoint.x - this.touchStartPoint.x)) {
                    this.isTouchComparing = true;
                    this.focus();
                    this.slideToPageX(e.touches[0].pageX, true);
                    e.preventDefault();
                    return false;
                }
                this.hasTouchMoved = true;
            }
        };
        this.onTouchEnd = () => {
            this.isTouchComparing = false;
            this.hasTouchMoved = false;
        };
        this.onBlur = () => {
            this.stopSlideAnimation();
            this.isFocused = false;
        };
        this.onFocus = () => {
            this.isFocused = true;
        };
        this.onKeyDown = (e) => {
            if (this.keyboardSlideAnimationTimeoutId) {
                return;
            }
            const key = e.key;
            if (!Object.keys(KeySlideOffset).includes(key)) {
                return;
            }
            this.startSlideAnimation(KeySlideOffset[key]);
        };
        this.onKeyUp = (e) => {
            if (!this.keyboardSlideAnimationTimeoutId) {
                return;
            }
            if (!Object.keys(KeySlideOffset).includes(e.key)) {
                return;
            }
            this.stopSlideAnimation();
        };
        this.resetWidth = () => {
            this.imageWidth = this.offsetWidth;
            this.afterImageContainerElement.style.width = `${this.offsetWidth}px`;
        };
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
        this.beforeElement = shadowRoot.getElementById('before');
        this.afterElement = shadowRoot.getElementById('after');
        this.afterImageContainerElement = shadowRoot.getElementById('afterImageContainer');
    }
    connectedCallback() {
        this.shadowRoot.querySelectorAll('slot').forEach((slot) => {
            slot.addEventListener('slotchange', (e) => {
                if (!isSlot(e.target)) {
                    return;
                }
                e.target.assignedElements().forEach(this.hydrate);
            });
        });
        this.querySelectorAll('img').forEach(this.hydrate);
        window.addEventListener('resize', this.resetWidth);
        this.slide(0);
        this.setAttribute('tabindex', '0');
        this.addEventListener('keydown', this.onKeyDown);
        this.addEventListener('keyup', this.onKeyUp);
        this.addEventListener('focus', this.onFocus);
        this.addEventListener('blur', this.onBlur);
        this.addEventListener('touchstart', this.onTouchStart);
        this.addEventListener('touchmove', this.onTouchMove, {
            passive: false,
        });
        this.addEventListener('touchend', this.onTouchEnd);
        this.addEventListener('mousedown', this.onMouseDown);
        this.resetWidth();
        this.classList.add('rendered');
    }
    disconnectedCallback() {
        if (this.transitionTimer) {
            window.clearTimeout(this.transitionTimer);
        }
    }
    reset() {
        this.exposure = 50;
        this.slide(0);
    }
    slide(increment = 0, transition = false) {
        this.exposure = inBetween(this.exposure + increment, 0, 100);
        if (transition) {
            const transitionTime = 100;
            this.afterElement.style.transition = `width ${transitionTime}ms`;
            this.transitionTimer = window.setTimeout(() => {
                this.afterElement.style.transition = null;
                this.transitionTimer = null;
            }, transitionTime);
        }
        this.afterElement.style.width = `${this.exposure}%`;
    }
    slideToPageX(pageX, transition = false) {
        const x = pageX - this.getBoundingClientRect().left - window.scrollX;
        this.exposure = (x / this.imageWidth) * 100;
        this.slide(0, transition);
    }
    startSlideAnimation(offset) {
        const fps = 120;
        const fraction = 1 * offset;
        const slide = () => {
            this.keyboardSlideAnimationTimeoutId = window.setTimeout(() => {
                this.animationRequestId = window.requestAnimationFrame(slide);
            }, 1000 / fps);
            this.slide(fraction);
        };
        slide();
    }
    stopSlideAnimation() {
        if (!this.keyboardSlideAnimationTimeoutId) {
            return;
        }
        window.clearTimeout(this.keyboardSlideAnimationTimeoutId);
        window.cancelAnimationFrame(this.animationRequestId);
        this.keyboardSlideAnimationTimeoutId = null;
        this.animationRequestId = null;
    }
}
window.customElements.define('img-comparison-slider', HTMLImgComparisonSliderElement);
